<template>
  <section>
    <div class="content-header">
      <h2>The Career Resources Questionnaire </h2>
    </div>
    <div class="content-wrapper">
      <p>The <a href="http://www.cresogo.com/crqmainpage" target="_blank">Career Resources Questionnaire</a> can be a useful tool to engage students in discussions about employability, career development, career success and career counselling. Why not try it yourself? The questionnaire is an excellent career self-assessment experience.</p>
      <p>To incorporate the questionnaire into your curriculum, you could let students complete the questionnaire as a homework assignment. Instruct them to bring the questionnaire results (their Basic Report) to the next class. Based on this experience and the questionnaire results you can:</p>
      <ul>
        <li>Let students reflect on their own career goals and what resources might help them to achieve their goals</li>
        <li>Engage students in a discussion about factors that lead to career success and discuss the nature and meaning of career success</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
